import { Button,
  Col,
  Container,
  Dropdown,
  Row,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form } from 'react-bootstrap'
import { getFooterMenu, getSocialFooter } from '~/utils/constants'
import config from '~/config'
import { withI18n, withUser } from '~/hocs'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import React, { useState, useEffect } from 'react'
import { assetURL } from '~/utils'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { loadTotalUserDownload } from '~/store/meta/actions'
import { makeSelectTotalUserDownload } from '~/store/meta/selector'
import DownloadInfoService from '~/services/DownloadInfoService'
import Countdown from 'react-countdown'
import ReCAPTCHA from "react-google-recaptcha"
import UserService from '~/services/UserService'
import axios from 'axios'
import moment from 'moment-timezone'
import { routes } from '~/router'

const SocialNetworks = withI18n(({ t, lng, userID }) => {
  const { data: session } = useSession()
  const socialLinks = getSocialFooter(lng)
  const [show, setShow] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalCapcha, setShowModalCapcha] = useState(false);
  const handleClose = () => {
    setMessage("")
    setCaptcha("")
    setShow(false)
    setIsCheckbox(false)
  };
  const handleShow = () => setShow(true);
  const [message,setMessage] = useState("")
  const [captcha,setCaptcha] = useState("")
  const [isCheckbox, setIsCheckbox] = useState(false)
  const [errorText,setErrorText] = useState("")
  const [errorCaptcha,setErrorCaptcha] = useState("")
  const [errorCheckbox,setErrorCheckbox] = useState("")
  const handleSubmit = async () => {
    if (message && isCheckbox) {
      setShowModalCapcha(true)
      setShow(false)
    } 
    else {
      !message && setErrorText("メッセージを入力してください。")
      !isCheckbox && setErrorCheckbox("※チェックボックスにチェックを入れ、送信してください。")
    }
  }
  const handleChangeCapcha = (capcha) => {
    try {
      if (capcha) {
        setErrorCaptcha("")
        UserService.createFeedback({
            message,
            user_id: userID,
          }).then(()=>{
            setMessage("")
            setIsCheckbox(false)
            setShowModalCapcha(false)
            setShowModalSuccess(true)
          })
      }
    } catch (error) {
      console.log(error)
      alert('エラー')
    }
  }
  return (
    <div className="text-center btn-socials">
      {socialLinks.map((item, idx) => (
        <OverlayTrigger
          key={idx}
          placement="top"
          overlay={(props) => (
            <Tooltip id="button-tooltip" {...props}>
              {item.tooltip_name}
            </Tooltip>
          )}
        >
          <a
            href={t(`href_${item.name}`)}
            target="_blank"
            key={idx}
            className="pr-1"
          >
            <Button>{item.icon}</Button>
          </a>
        </OverlayTrigger>
      ))}
      <Modal size="md" show={show} onHide={handleClose} className="modal-feedback-user" centered>
        <Modal.Header closeButton>
          <Modal.Title>サイト改善にご協力ください</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>あなたのご意見をお聞かせください。</p>
          <p>※こちらは<span style={{fontWeight: "bold"}}>個別返信はしておりません</span>。予めご了承くださいませ。</p>
          <p className="my-2">返信が必要なお問い合わせは<a href="https://help.freebie-ac.jp/inquiry?language=jp" className="ac-text-photo text-decoration-none" target="_blank">お問い合わせフォーム</a>よりご連絡ください。</p>
          <textarea name="message" onChange={(e)=>{
            if (e.target.value) {
              setErrorText("")
            } else {
              setErrorText("メッセージを入力してください。")
            }
            setMessage(e.target.value)
          }} autocomplete="off"
           class="form-control ac-form-control" rows={6}></textarea>
            <div className="ac-mt-1 d-flex flex-wrap justify-content-center">
              <Form.Group className="mb-3 " controlId="formBasicCheckbox">
                <Form.Check type="checkbox" className={`checkboxFeedback ${errorCheckbox ? `checkboxFeedbackError` : ''}`} label="個別返信がないことを理解しました" defaultChecked={isCheckbox} onChange={(e)=>{
            if (e.target.checked) {
              setErrorCheckbox("")
            } else {
              setErrorCheckbox("※チェックボックスにチェックを入れ、送信してください。")
            }
            setIsCheckbox(e.target.checked)
          }}/>
              </Form.Group>
            </div>
          <div style={{ }}>
            {errorText && <>
              <span className="ml-1 text-danger">{errorText}</span> <br/>
              </>}
            {errorCheckbox && <span className="ml-1 text-danger">{errorCheckbox}</span>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
          ご意見・ご要望の送信
          </Button>
        </Modal.Footer>
      </Modal>
      <Button onClick={handleShow}>
          <i className="fa-solid fa-comment-dots pr-1" aria-hidden="true" />
          {t('chat_content')}
      </Button>
      <Modal size="md" show={showModalCapcha} onHide={()=>{setShowModalCapcha(false)}} className="modal-feedback-user-capcha" centered>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>
        <div className="mt-2" style={{marginLeft:"18%"}}>
            <ReCAPTCHA
              sitekey={process.env.RECAPTCHA_KEY}
              onChange={(value)=>{
                handleChangeCapcha(value)
              }}
              hl='ja'
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal size="md" show={showModalSuccess} onHide={()=>{setShowModalSuccess(false)}} className="modal-feedback-user" centered>
        <Modal.Header closeButton>
          <Modal.Title>サイト改善にご協力ください</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        送信が完了しました。貴重なご意見をありがとうございました！
        </Modal.Body>
      </Modal>
    </div>
  )
})

// const PAYMENT_METHODS = [
//   'visa_blank.webp',
//   'mastercard.webp',
//   'maestro.webp',
//   'visa_debit.webp',
//   'mastercard_debit.webp',
//   'visa_electron.webp',
//   'american.webp',
//   'jcb.webp',
//   'wechat.webp',
//   'alipay.webp',
// ]
// const PAYMENT_METHOD_CLASSES = [
//   'visa-blank',
//   'mastercard',
//   'maestro',
//   'visa-debit',
//   'mastercard-debit',
//   'visa-electron',
//   'american',
//   'jcb',
//   'wechat',
//   'alipay',
// ]

// const Payments = withI18n(({ t }) => (
//   <div className="payments d-flex justify-content-lg-end ">
//     <div>
//       {t('secure_payment')}
//       <div className="payment-methods">
//         {PAYMENT_METHODS.map((name, idx) => (
//           <img
//             className={`lazyload payment-image ${PAYMENT_METHOD_CLASSES[idx]}`}
//             key={name}
//             src={`${Config.assetPrefix}/asset/image/pay/${name}`}
//             alt="Payment method"
//           />
//         ))}
//       </div>
//     </div>
//   </div>
// ))

const Footer = ({ t, lng, totalUser, loadTotalUserDownload, profile }) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)

  const [countDownload, setCountDownload] = useState({
    yesterday: 0,
    last_month: 0,
  })
  useEffect(() => {
    DownloadInfoService.getDownloadYesterday().then((downloadInfo) => {
      setCountDownload(countDownload => ({
        ...countDownload,
        yesterday: downloadInfo.countDownloadYesterday,
      }))
    })
    DownloadInfoService.getDownloadLastMonth().then((downloadInfo) => {
      setCountDownload(countDownload => ({
        ...countDownload,
        last_month: downloadInfo.countDownloadLastMonth,
      }))
    })
  }, [])
  const renderFooterMenus = React.useMemo(() => {
    const menus = getFooterMenu(lng)
    return (
      <>
        <div className="column-menu-container mt-3 mt-md-0">
          {menus.map((menu, idx) => (
            <div className="column-menu mt-2 mt-sm-0" key={idx}>
              <div className="menu-title">{t(menu.name)}</div>
              <div className="menu-items-container">
                {menu.items.map((item) => {
                  if (!item.items) {
                    return (
                      <Link
                        key={item.name}
                        locale={lng}
                        href={t(`href_${item.name}`)}
                      >
                        <a
                          target={item.href !== '/' ? '_blank' : ''}
                          className="menu-item"
                        >
                          {t(item.name)}
                        </a>
                      </Link>
                    )
                  }

                  return (
                    <Dropdown
                      className="menu-item-dropdown"
                      drop="down"
                      key={item.name}
                    >
                      <Dropdown.Toggle variant="light">
                        {t(item.name)}
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '1fr 1fr',
                          width: 290,
                        }}
                      >
                        {item.items.map((i) => (
                          <Dropdown.Item
                            key={i.name}
                            href={t(`href_${i.name}`)}
                            target="_blank"
                          >
                            {t(i.name)}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )
                })}
              </div>
            </div>
          ))}
        </div>
      </>
    )
  }, [lng])
  React.useEffect(() => {
    loadTotalUserDownload()

    // Check to show price modal
    const now = new Date()
    const seenKey = `price_modal_seen_${profile.email}_${now.getFullYear()}_${now.getMonth() + 1}_${now.getDate()}`
    const isSeen = localStorage.getItem(seenKey)
    if (!isSeen && profile.email && !profile.premium_info &&
      Date.now() > Date.parse(`${config.showPriceModalFrom}`) &&
      Date.now() < Date.parse(`${config.showPriceModalTo}`)) {
      setShow(true)
      localStorage.setItem(seenKey, true)
    }
  }, [])

  if (!totalUser) return null
  const currentUserCountry = totalUser[lng]
  if (!currentUserCountry) return null

  return (
    <>
      <style jsx global>{`
        .price-modal .modal-dialog {
          max-width: fit-content;
        }
        .price-modal .modal-content, .price-modal .modal-header, .price-modal .modal-body {
          padding: 0;
          margin: 0;
          border: none;
        }
        .price-modal .modal-header .close {
          padding: 0;
          margin: 0 0 0 -20px;
        }
        .price-modal .price-date {
          position: absolute;
          color: #fff;
          font-size: 24px;
          font-weight: 800;
          top: 46px;
          left: 40px;
        }

        .price-modal .countdown {
          position: absolute;
          color: #fff;
          font-size: 20px;
          font-weight: 100;
          top: 50px;
          left: 184px;
        }
    `}</style>
      <div className="bg-dark text-white">
        <div className="container">
          <div className="row">
            <div className="col-md-4 ac-py-1">{t('count_download_last_day')}：{countDownload.yesterday}</div>
            <div className="col-md-4 ac-py-1 text-md-center">
              {t('count_download_last_month')}：{countDownload.last_month}
            </div>
            <div className="col-md-4 ac-py-1 text-md-right">
            {t('num_of_total_free_user')} :
              <span dangerouslySetInnerHTML={{ __html: t('over_no_users') }}/>
            </div>
          </div>
        </div>
      </div>
      <div className="container-footer ac-footer ac-py-3 overflow-hidden">
        <Container>
          <Row>
            <Col sm={4} md={3} className="mb-2">
              <h4 className="ac-mb-1 ac-pb-1">
              {t('recommend_about_video')}
                <i
                  className="fa fa-angle-right d-block d-sm-none float-right"
                  aria-hidden="true"
                />
              </h4>
              <ul className="list-menu-item">
                <li className="ac-p-0 ac-mb-1">
                  <a href="/about-us/" title="運営会社">
                  {t('get_know_to_us')}
                  </a>
                </li>
                <li className="ac-p-0 ac-mb-1">
                  <a href="/privacy-policy/" title="個人情報保護方針">
                  {t('privacy_policy_head')}
                  </a>
                </li>
                <li className="ac-p-0">
                  <a href="/sitemap" title="サイトマップ">
                  {t('sitemap')}
                  </a>
                </li>
              </ul>
            </Col>
            <Col sm={4} md={3} className="col-lg mb-2">
              <h4 className="ac-mb-1 ac-pb-1">
                {t('register_member')}
                <i
                  className="fa fa-angle-right d-block d-sm-none float-right"
                  aria-hidden="true"
                />
              </h4>
              <ul className="list-menu-item">
                <li className="ac-p-0 ac-mb-1">
                  <a
                    href="https://accounts.ac-illust.com/signup?lang=jp&serviceURL=https%3A%2F%2Fvideo-ac.com%2Fapi%2Fauth%2Fsignin%3Fredirect%3Dhttps%3A%2F%2Fvideo-ac.com%2Fabout-us"
                  >
                    {t('join_free')}
                  </a>
                </li>
                <li className="ac-p-0 ac-mb-1">
                  <a
                    href="https://premium.photo-ac.com/premium/campaign?ref=videoAC"
                    title="プレミアム会員登録"
                    target="_blank"
                  >
                    {t('go_premium')}
                  </a>
                </li>
                <li className="ac-p-0">
                  <a
                    href={routes.creator.reg.href}
                    title="無料クリエイター会員登録"
                  >
                    {t('register_creator')}
                  </a>
                </li>
              </ul>
            </Col>
            <Col sm={4} md={3} className="col-lg mb-2">
              <h4 className="ac-mb-1 ac-pb-1">
                {t('premium_member_service')}
                <i
                  className="fa fa-angle-right d-block d-sm-none float-right"
                  aria-hidden="true"
                />
              </h4>
              <ul className="list-menu-item">
                <li className="ac-p-0 ac-mb-1">
                  <a
                    href="https://premium.photo-ac.com/premium/campaign?ref=videoAC"
                    title="プレミアム会員登録"
                    target="_blank"
                  >
                    {t('go_premium')}
                  </a>
                </li>
                <li className="ac-p-0 ac-mb-1">
                  <a
                    href="https://premium.photo-ac.com/premium/business"
                    title="法人・複数名向けプラン"
                    target="_blank"
                  >
                    {t('plan_for_corporations')}
                  </a>
                </li>
              </ul>
            </Col>
            <Col sm={4} md={3} className="col-lg mb-2">
              <h4 className="ac-mb-1 ac-pb-1">
                {t('help&guide')}
                <i
                  className="fa fa-angle-right d-block d-sm-none float-right"
                  aria-hidden="true"
                />
              </h4>
              <ul className="list-menu-item">
                <li className="ac-p-0 ac-mb-1">
                  <a
                    href="https://help.freebie-ac.jp/"
                    target="_blank"
                    title="ヘルプ"
                  >
                    {t('help')}
                  </a>
                </li>
                <li className="ac-p-0 ac-mb-1">
                  <a href="/terms/" title="利用規約">
                    {t('term')}
                  </a>
                </li>
                <li className="ac-p-0">
                  <a href="/terms_premium/" title="プレミアム会員利用規約">
                    {t('premium_membership_terms_of_service')}
                  </a>
                </li>
              </ul>
            </Col>
            <Col sm={4} md={3} className="col-lg mb-2">
              <h4 className="ac-mb-1 ac-pb-1">
                {t('group_site')}
                <i
                  className="fa fa-angle-right d-block d-sm-none float-right"
                  aria-hidden="true"
                />
              </h4>
              <ul className="list-menu-item">
                <li className="ac-p-0 ac-mb-1">
                  <a
                    target="_blank"
                    href="https://www.ac-illust.com/"
                    title="イラストAC"
                  >
                    {t('illustAC')}
                  </a>
                </li>
                <li className="ac-p-0 ac-mb-1">
                  <a
                    target="_blank"
                    href="https://www.photo-ac.com/"
                    title="シルエットAC"
                  >
                    {t('photoAC')}
                  </a>
                </li>
                <li className="ac-p-0 ac-mb-1">
                  <a
                    target="_blank"
                    href="https://www.silhouette-ac.com/"
                    title="シルエットAC"
                  >
                    {t('sihouteAC')}
                  </a>
                </li>
                <li className="ac-p-0 ac-mb-1">
                  <a
                    target="_blank"
                    href="https://www.freebie-ac.jp/"
                    title="フリービーAC"
                  >
                    {t('freebie_ac')}
                  </a>
                </li>
                <li className="ac-p-0 ac-mb-1">
                  <a
                    target="_blank"
                    href="https://www.new-year.bz/"
                    title="年賀状AC"
                  >
                    {t('newyearAC')}
                  </a>
                </li>
                <li className="ac-p-0 ac-mb-1">
                  <a
                    target="_blank"
                    href="https://www.design-ac.net/"
                    title="designAC"
                  >
                    {t('designAC')}
                  </a>
                </li>
                <li className="ac-p-0 ac-mb-1">
                  <a
                    target="_blank"
                    href="https://ac-data.info/"
                    title="ACdata"
                  >
                    {t('dataAC')}
                  </a>
                </li>
                <li className="ac-p-0">
                  <a
                    target="_blank"
                    href="https://meisai-ac.com/"
                    title="明細AC"
                  >
                    {t('meisaiAC')}
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
          <SocialNetworks userID={profile.id}/>
          <div className="text-center ac-mt-1">
            Copyright {moment().tz('Asia/Tokyo').year()} -
            <a
              href="https://www.video-ac.com/"
              className="ac-text-blue ml-2 mr-3 link_hover"
              title="動画AC"
            >
              {t('videoAC')}
            </a>
            All Rights Reserved.
          </div>
        </Container>
      </div>
      {/* Price popup */}
      <Modal show={show} onHide={handleClose} centered className='price-modal'>
        <Modal.Header
          closeButton>
          <a href={config.premiumUrl} target='_blank'>
            <span className='price-date'>2月1日</span>
            <span className='countdown'>
              <Countdown
                date={Date.parse(`${config.showPriceModalTo}`)}
                intervalDelay={0}
                precision={3}
                renderer={props => <div>{props.days}日{props.hours}時間{props.minutes}分{props.seconds}秒</div>}
              />
            </span>
            <img src={assetURL('asset/image/premium_videoAC.png')} />
          </a>
        </Modal.Header>
      </Modal>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  totalUser: makeSelectTotalUserDownload(),
})

const mapDispatchToProps = (dispatch) => ({
  loadTotalUserDownload: (payload) => dispatch(loadTotalUserDownload(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(withUser(Footer)))
