import { useEffect } from 'react';
import { AppCommonProps } from 'types/app';
import config from '~/config';
import { withI18n } from '~/hocs';

const Chatbot = ({ className }: AppCommonProps) => {
  useEffect(() => {
    const div = document.createElement('div');
    div.setAttribute('get-ac-chat', 'true');

    const script = document.createElement('script');
    script.src = `${config.chatBotURL}/chat-embed.js`;
    script.defer = true;

    document.body.appendChild(div);
    document.body.appendChild(script);

    const setChatbotPosition = () => {
      const adsbygoogle = document.querySelector('.adsbygoogle-noablate[data-anchor-status="displayed"]');
      const chatbotIcon = document.querySelector('.ac-chat-bot-icon');
      const bottomOffset = adsbygoogle?.style?.bottom ? 20 : 135;

      if (chatbotIcon) {
        // Thiết lập vị trí chatbot
        chatbotIcon.style.cssText = `
          bottom: ${bottomOffset}px;
          transition: .3s;
          display: block;
        `;
        // Tạo hoặc tìm nút đóng
        let closeButton = chatbotIcon.querySelector('.close-button-chat-bot');
        if (!closeButton) {
          closeButton = document.createElement('span');
          closeButton.className = 'close-button-chat-bot';
          closeButton.style.cssText = `
            position: fixed;
            height: 18px;
            width: 18px;
            border-radius: 50%;
            font-size: 18px;
            padding-bottom: 2px;
            background-color: rgba(0, 0, 0, 0.7);
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: 0.5s;
            right: 13px;
            z-index: 1000;
          `;
          closeButton.textContent = '×';
          chatbotIcon.appendChild(closeButton);
      
          // Lắng nghe sự kiện click
          closeButton.addEventListener('click', () => {
            document.querySelector('div[get-ac-chat="true"]').style.display = 'none';
            chatbotIcon.style.display = 'none';
          });
        }
      }
      
    };
    const handleEventsScrool = () => {
      const adsbygoogle = document.querySelector('.adsbygoogle-noablate[data-anchor-status="displayed"]');
      const chatbotIcon = document.querySelector('.ac-chat-bot-icon');
      const bottomOffset = adsbygoogle?.style?.bottom ? 135 : 20;
      chatbotIcon?.style?.bottom = `${bottomOffset}px`;
      chatbotIcon?.style?.transition = '.3s';
      chatbotIcon?.style?.display = 'block';
      if (chatbotIcon) {
        // Thiết lập vị trí chatbot
        chatbotIcon.style.cssText = `
          bottom: ${bottomOffset}px;
          transition: .3s;
          display: block;
        `;
        // Tạo hoặc tìm nút đóng
        let closeButton = chatbotIcon.querySelector('.close-button-chat-bot');
        if (!closeButton) {
          closeButton = document.createElement('span');
          closeButton.className = 'close-button-chat-bot';
          closeButton.style.cssText = `
            position: fixed;
            height: 18px;
            width: 18px;
            border-radius: 50%;
            font-size: 18px;
            padding-bottom: 2px;
            background-color: rgba(0, 0, 0, 0.7);
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: .3s;
            right: 13px;
            z-index: 1000;
          `;
          closeButton.textContent = '×';
          chatbotIcon.appendChild(closeButton);
      
          // Lắng nghe sự kiện click
          closeButton.addEventListener('click', () => {
            document.querySelector('div[get-ac-chat="true"]').style.display = 'none';
            chatbotIcon.style.display = 'none';
          });
        }
      }
    };
    const handleEvents = () => {
      setChatbotPosition();
    };

    window.addEventListener('load', handleEvents);
    window.addEventListener('scroll', handleEventsScrool);
    window.addEventListener('resize', handleEvents);

    document.addEventListener('click', (e) => {
      if (e.target.closest('.adsbygoogle-noablate')) {
        setTimeout(() => {
          handleEvents();
        }, 100);
      } else {
        handleEvents();
      }
    });

    return () => {
      window.removeEventListener('load', handleEvents);
      window.removeEventListener('scroll', handleEvents);
      window.removeEventListener('resize', handleEvents);
      document.removeEventListener('click', handleEvents);
    };
  }, []);

  return <div className={className}></div>;
};

export default withI18n(Chatbot);
