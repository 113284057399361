import api_ext from './API-EXT'

export default {
  createFeedback(data: any) {
    return api_ext.post('/feedback/createFeedback', data)
  },
  getDataReceipt(data: any) {
    return api_ext.post('/receipt/getDataReceipt', data)
  },
  downloadReceipt(data: any) {
    return api_ext.post('/receipt/downloadReceipt', data)
  },
  updateUserDataReceipt(data: any) {
    return api_ext.put('/receipt/updateUserDataReceipt', data)
  },
  getNoadsInfo(data: any) {
    return api_ext.post('/user/getNoadsInfo', data)
  },
}