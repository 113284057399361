import api, { genHeader } from './API'
import api_ext from './API-EXT'
export default {
  getDownloadYesterday() {
    return api.get('/video/download/yesterday');
  },
  getDownloadLastMonth() {
    return api.get('/video/download/lastmonth');
  },
  checkFirstDownload({user_id}:any) {
    return api_ext.post('/download/checkFirstDownload', {user_id});
  },
}
